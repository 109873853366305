import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDB88DwyTCGGtX27vqLnlIdqFKvJk4AkTA",
  authDomain: "gamer-profile-project.firebaseapp.com",
  projectId: "gamer-profile-project",
  storageBucket: "gamer-profile-project.appspot.com",
  messagingSenderId: "2425602874",
  appId: "1:2425602874:web:5c35113c37e02e0febc603",
  measurementId: "G-181ZEHTTWH"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;